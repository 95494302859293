import React from "react";

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <a href="#!" className="footer-logo">
              <img src="img/logo.png" alt="7seer logo" className="logo" />
            </a>

            <ul className="social-links">
              <li>
                <a className="footer-link" href="#!">
                  <ion-icon
                    className="social-icon"
                    name="logo-linkedin"
                  ></ion-icon>
                </a>
              </li>
              <li>
                <a className="footer-link" href="#!">
                  <ion-icon
                    className="social-icon"
                    name="logo-facebook"
                  ></ion-icon>
                </a>
              </li>
              <li>
                <a className="footer-link" href="#!">
                  <ion-icon
                    className="social-icon"
                    name="logo-twitter"
                  ></ion-icon>
                </a>
              </li>
            </ul>

            <p className="copyright">
              Copyright &copy; <span className="year">{year}</span> by 7seer
              LTD. All rights reserved.
            </p>
          </div>

          <div className="col-md-3 col-sm-6" id="contact">
            <p className="footer-heading">Contact us</p>
            <address className="contacts">
              <p className="address">7seer LTD. All rights reserved.</p>
              <p>
                <a className="footer-link" href="tel:+2349122209899">
                  +234 [0] 912-220-9899
                </a>
                <br />
                <a className="footer-link" href="mailto:contact@7seer.com">
                  contact@7seer.com
                </a>
              </p>
            </address>
          </div>

          <div className="col-md-3 col-sm-4">
            <nav className="nav-col">
              <p className="footer-heading">Company</p>
              <ul className="footer-nav">
                <li>
                  <a className="footer-link" href="/about">
                    About 7Seer
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/services">
                    For Business
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="#!">
                    Careers
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-md-3 col-sm-4">
            <nav className="nav-col">
              <p className="footer-heading">Resources</p>
              <ul className="footer-nav">
                <li>
                  <a
                    className="footer-link"
                    href="https://hashnode.com/@7seer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.fiverr.com/mlordjames"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Help center
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
