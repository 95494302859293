import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top scrolled">
      <div className="container">
        <a className="navbar-brand" href="#home">
          <img src="img/logo.png" width="130" alt="7seer logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarResponsive">
          <div className="offsetcanvas-header mt-3">
            <h5 className="text-white py-2">
              <img
                className="mobile-menu-logo"
                src="img/logo.png"
                width="100"
                alt="7seer logo"
              />
            </h5>
            <button type="button" className="btn-close">
              <ion-icon
                className="icon-mobile-nav"
                name="close-outline"
              ></ion-icon>
            </button>
          </div>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services">
                Services
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contact
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://hashnode.com/@7seer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn btn-info btn-fix" href="/#quote">
                Request Quote
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
