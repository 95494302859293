import React from "react";
import Tools from "../../layout/Tools";

const Services = () => {
  return (
    <main>
      <section className="service-hero">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="content">
                <h1>
                  Accelerate Data
                  <br />
                  pipeline development <br />
                  and deployment.
                </h1>
                <p className="header-text">
                  Hire our experienced team quickly and seamlessly.
                </p>

                <Tools />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-12">
              <h2 className="service-heading">
                Avoid delays, check through our list of <br />
                custom services on Fiverr.
              </h2>
              <article className="service-article">
                Hire our experienced team quickly and seamlessly.
              </article>
            </div>
          </div>

          <div className="row services-items">
            <div className="col-md-3 col-sm-6">
              <div className="card services-card">
                <a
                  href="https://www.fiverr.com/mlordjames/use-octoparse-to-extract-web-data-to-excel-csv?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=1&seller_online=true&imp_id=91a9efd5-2ef1-4e03-bb0e-2fcf304172e5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="card-img-top"
                    src="img/services-card-imgs/rizwrvbcdwghfhdxekxn.webp"
                    alt="Web scraping ads"
                  />
                </a>
                <div className="card-body card-content">
                  <p className="card-text">
                    <a
                      href="https://www.fiverr.com/mlordjames/use-octoparse-to-extract-web-data-to-excel-csv?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=1&seller_online=true&imp_id=91a9efd5-2ef1-4e03-bb0e-2fcf304172e5"
                      target="_blank"
                      rel="noreferrer"
                      className="card-text-link"
                    >
                      I will build web scrapers or crawlers with octoparse
                    </a>
                  </p>
                </div>
                <hr className="card-separator" />
                <div className="card-footer starting-at-box">
                  <ion-icon
                    className="starting-at-icon"
                    name="heart-circle-outline"
                  ></ion-icon>
                  <div className="starting-at-items">
                    <span className="starting-at">Starting at</span>
                    <span className="starting-at-price">US$50</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="card services-card">
                <a
                  href="https://www.fiverr.com/mlordjames/solve-any-octoparse-web-scraping-issues?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=2&seller_online=true&imp_id=eb7d49d4-f56b-4224-a481-fcff3d729c36"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="card-img-top"
                    src="img/services-card-imgs/d37e63cdd45bb8c3656870a2a17d6fc06f560eff.webp"
                    alt="Octoparse scraping issues"
                  />
                </a>
                <div className="card-body card-content">
                  <p className="card-text">
                    <a
                      href="https://www.fiverr.com/mlordjames/solve-any-octoparse-web-scraping-issues?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=2&seller_online=true&imp_id=eb7d49d4-f56b-4224-a481-fcff3d729c36"
                      target="_blank"
                      rel="noreferrer"
                      className="card-text-link"
                    >
                      I will solve any octoparse web scraping issues
                    </a>
                  </p>
                </div>
                <hr className="card-separator" />
                <div className="card-footer starting-at-box">
                  <ion-icon
                    className="starting-at-icon"
                    name="heart-circle-outline"
                  ></ion-icon>
                  <div className="starting-at-items">
                    <span className="starting-at">Starting at</span>
                    <span className="starting-at-price">US$40</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="card services-card">
                <a
                  href="https://www.fiverr.com/mlordjames/scrape-products-hotels-social-media-businesses-in-24hrs?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=3&seller_online=true&imp_id=57d42907-9cf4-4adb-8bdb-4d88759a22fc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="card-img-top"
                    src="img/services-card-imgs/4550bc6db352ad6db65b5dbabc537a8d5f15d4db.webp"
                    alt="Difficult web data"
                  />
                </a>
                <div className="card-body card-content">
                  <p className="card-text">
                    <a
                      href="https://www.fiverr.com/mlordjames/scrape-products-hotels-social-media-businesses-in-24hrs?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=3&seller_online=true&imp_id=57d42907-9cf4-4adb-8bdb-4d88759a22fc"
                      target="_blank"
                      rel="noreferrer"
                      className="card-text-link"
                    >
                      I will scrape difficult web data from any website in
                      any...
                    </a>
                  </p>
                </div>
                <hr className="card-separator" />
                <div className="card-footer starting-at-box">
                  <ion-icon
                    className="starting-at-icon"
                    name="heart-circle-outline"
                  ></ion-icon>
                  <div className="starting-at-items">
                    <span className="starting-at">Starting at</span>
                    <span className="starting-at-price">US$50</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="card services-card">
                <a
                  href="https://www.fiverr.com/mlordjames/build-machine-learning-models-with-python-and-r?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=4&seller_online=true&imp_id=cfe6ba1f-2d97-4ffc-a4b6-e4cb75467c20"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="card-img-top"
                    src="img/services-card-imgs/06f289f1117a0e276d6b53e6dd10045b29ad7dc7.webp"
                    alt="Machine learning models with python and r"
                  />
                </a>
                <div className="card-body card-content">
                  <p className="card-text">
                    <a
                      href="https://www.fiverr.com/mlordjames/build-machine-learning-models-with-python-and-r?context_referrer=user_page&ref_ctx_id=e9124e8e36529abe19e6fa80d60cf872&pckg_id=1&pos=4&seller_online=true&imp_id=cfe6ba1f-2d97-4ffc-a4b6-e4cb75467c20"
                      target="_blank"
                      rel="noreferrer"
                      className="card-text-link"
                    >
                      I will build machine learning models with python and r
                    </a>
                  </p>
                </div>
                <hr className="card-separator" />
                <div className="card-footer starting-at-box">
                  <ion-icon
                    className="starting-at-icon"
                    name="heart-circle-outline"
                  ></ion-icon>
                  <div className="starting-at-items">
                    <span className="starting-at">Starting at</span>
                    <span className="starting-at-price">US$100</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ads">
        <div className="inner-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-7">
                <h4 className="ads-heading mb-4">
                  From Real estate companies, to <br /> e-commerce, data
                  resellers and <br />
                  local startups, we have provided
                  <br />
                  our data services to more than 500 <br />
                  SMEs from 40+ countries in the last <br />
                  three years.
                </h4>
                <p className="ads-text">
                  We have built a strong team of dedicated freelancers, and{" "}
                  <br />
                  networks with service providers in other fields including{" "}
                  <br />
                  software and web developers.
                </p>
              </div>

              <div className="col-sm-5">
                <img
                  className="img-fluid rounded"
                  src="img/arif-riyanto-G1N9kDHqBrQ-unsplash-edited.jpg"
                  alt="A man studying data"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Services;
