import React from "react";
import Tools from "../../layout/Tools";
import QuoteForm from "./QuoteForm";

const Home = () => {
  return (
    <main>
      <section className="hero">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="content">
                <h1 style={{ lineHeight: "1.3" }}>
                  Empowering Business Growth <br />
                  A World-Class Data Engineering <br />
                  Team at Your Service
                </h1>
                <p className="header-text">
                  Data is the new oil, we build the extraction rigs, the
                  pipelines, <br />
                  and the storage solutions to power your business growth.
                </p>
                <a href="#quote" className="btn btn-info btn-fix">
                  Request Quote
                </a>
                {/* <a href="#!" className="btn btn-light btn-fix ml-5">
                  Join Network
                </a> */}

                <Tools />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className="container mb-5">
          <div className="row">
            <div className="col-sm-6 services-heading-box">
              <h2 className="services-heading">
                Extract, Clean & Store. Build with accurate data
              </h2>
            </div>
            <div className="col-sm-6 services-text-box">
              <p className="services-text">
                Unlocking the power of data, one extraction at a time.
                Empowering your business with accurate and reliable data.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <div className="card service-card">
                <div className="card-body">
                  <ion-icon
                    className="service-icon"
                    name="infinite-outline"
                  ></ion-icon>
                  <p className="service-title">Data Extraction</p>
                  <p className="service-text">
                    Building dynamic crawlers for web scraping and data
                    extraction using octoparse, python, and R into spreadsheets
                    and databases.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-3">
              <div className="card service-card">
                <div className="card-body">
                  <ion-icon
                    className="service-icon"
                    name="infinite-outline"
                  ></ion-icon>
                  <p className="service-title">Data Integration</p>
                  <p className="service-text">
                    Gathering data from various sources, cleaning and
                    transforming it, and loading it into a data warehouse
                    (ETL/ELT services).
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-3">
              <div className="card service-card">
                <div className="card-body">
                  <ion-icon
                    className="service-icon"
                    name="infinite-outline"
                  ></ion-icon>
                  <p className="service-title">Data Pipeline</p>
                  <p className="service-text">
                    Creating, scheduling and monitoring data pipelines and
                    workflows using tools such as Apache Airflow, AWS Glue, and
                    Google Cloud Dataflow.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-3">
              <div className="card service-card">
                <div className="card-body">
                  <ion-icon
                    className="service-icon"
                    name="infinite-outline"
                  ></ion-icon>
                  <p className="service-title">Cloud Data Engineering</p>
                  <p className="service-text">
                    Designing and building data infrastructure on cloud
                    platforms such as AWS, Google Cloud, and Microsoft Azure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ads">
        <div className="inner-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-7">
                <h4 className="ads-heading mb-4">
                  From Real estate companies, to <br />
                  e-commerce, data resellers and <br />
                  local startups, we have provided
                  <br />
                  our data services to more than 500 <br />
                  SMEs from 40+ countries in the last <br />
                  three years.
                </h4>
                <p className="ads-text">
                  Our wide and expansive talent network is comprised of <br />
                  experienced, pre-vetted software developers
                </p>
              </div>

              <div className="col-sm-5">
                <img
                  className="img-fluid"
                  src="img/fortune_img.a98c1447.png"
                  alt="A man smiling and operating a laptop"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="department">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2 className="department-heading mb-5">
                Discover our complete data engineering solutions.
              </h2>
              <span className="department-sub-text mb-4">
                Tap into our pool consisting of diverse data
                <br />
                roles.
              </span>
              <div className="row">
                <div className="col-md-6">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>Data Engineers</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>Data Extraction Specialists</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>Database Engineers</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>Machine Learning Experts</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 right-side">
              <div className="row">
                <div className="col-md-12">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>Cloud Data Specialist</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>NLP Experts</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>Data Scientists</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card department-card">
                    <div className="card-body department-card-body">
                      <h3>Blockchain Developers</h3>
                      <ion-icon
                        className="department-card-icon"
                        name="arrow-forward-outline"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta" id="quote">
        <div className="container">
          <div className="row cta-items">
            <div className="col-md-12">
              <h2>
                Ready to explore <br />
                our data <br />
                solutions?
              </h2>
              <p>
                Explain the data services necessary for your company's growth.
              </p>
            </div>

            <div className="col-md-8">
              <QuoteForm />
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 external-btn-box">
                  <a
                    href="https://www.upwork.com/freelancers/7seer"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-secondary btn-block external-quote-btn"
                  >
                    Go to Upwork
                    <ion-icon
                      className="department-card-icon"
                      name="arrow-forward-outline"
                    ></ion-icon>
                  </a>
                </div>

                <div className="col-md-12 external-btn-box">
                  <a
                    href="https://www.fiverr.com/mlordjames"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-secondary btn-block external-quote-btn"
                  >
                    Go to Fiverr
                    <ion-icon
                      className="department-card-icon"
                      name="arrow-forward-outline"
                    ></ion-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
