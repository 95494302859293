import React from "react";
import Tools from "../../layout/Tools";

const About = () => {
  return (
    <main>
      <section className="about-hero">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="content">
                <h1>
                  Our mission is to empower <br />
                  businesses globally with clean <br />
                  and reliable data solutions.
                </h1>
                <p className="header-text">
                  Build with a team trusted by many across the world.
                </p>

                <Tools />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6 col-sm-12 about-heading-box">
              <h2 className="about-heading">
                Our Amazing <br />
                Data Journey so far
              </h2>
              <div className="count-box">
                <div className="content-left">
                  <span>24%</span>
                  <p>World coverage</p>
                </div>
                <div className="content-right">
                  <span>600+</span>
                  <p>Satisfied customers</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 about-text-box">
              <p className="about-text">
                Avoid the rigours of hiring. Tap into our large pool of top
                African technical talent globally.
              </p>
              <div>
                <img
                  src="./img/7seer-analytics.jpg"
                  alt="Black Engineers"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>

          <div className="row our-story">
            <div className="col-sm-12 col-md-6 our-story-img-box">
              <img
                src="img/tech-about.jpg"
                alt="Black Engineers"
                className="img-fluid rounded"
              />
            </div>

            <div className="col-sm-12 col-md-6 about-heading-box">
              <h2 className="our-story-heading">Our Story</h2>
              <p className="our-story-text">
                7Seer started as a single freelancer offering web extraction
                services to small businesses and quickly evolved into a team of
                data engineers focused on enabling small business utilized the
                wonders of big data in growing their business. Now, we are a
                remote data engineering firm focusing on Data extraction,
                wrangling, integration, and storage.
              </p>

              <p className="our-story-text">
                Located in Abuja, that capital of Nigeria, and lead by Etiese James, it has helped several small and medium size businesses in over 45 countries employed data solutions to their growing business. We currently do not offer much in terms of analytics and ML solutions instead focusing more on the data collection and retention, as most small business have access to analyst but do not have access to proper data, we aim to change that.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ads">
        <div className="inner-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-7">
                <h4 className="ads-heading mb-4">
                  From Real estate companies, to <br /> e-commerce, data resellers and <br />
                  local startups, we have provided<br />
                  our data services to more than 500 <br />
                  SMEs from 40+ countries in the last <br />
                  three years.
                </h4>
                <p className="ads-text">
                  We have built a strong team of dedicated freelancers, and <br />
                  networks with service providers in other fields including <br />
                  software and web developers.
                </p>
              </div>

              <div className="col-sm-5">
                <img
                  className="img-fluid rounded"
                  src="img/arif-riyanto-G1N9kDHqBrQ-unsplash-edited.jpg"
                  alt="A man studying data"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;
