import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/layout/NavBar";
import Footer from "./components/layout/Footer";
import Home from "./components/pages/Home/Index";
import About from "./components/pages/About/Index";
import Services from "./components/pages/Services/Index";
import NotFound from "./components/pages/NotFound";
import ScrollToTp from "./components/utils/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTp />
      <div>
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
