import React, { Fragment } from "react";

const QuoteForm = () => {
  return (
    <Fragment>
      <form
        className="cta-form"
        name="quotation"
        enctype="multipart/form-data"
        method="POST"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="quotation" />
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="firstname">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstname"
              name="firstname"
              placeholder="John"
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              name="lastname"
              placeholder="Doe"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="joe@example.com"
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="phone">Whatsapp Number (Optional)</label>
            <input
              type="phone"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="+234 333 333 3333"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="file">Add media file</label>
          <input name="file" type="file" className="form-control" id="file" />
        </div>

        <div className="form-group">
          <label htmlFor="details">More Info</label>
          <textarea
            className="form-control cta-textarea"
            name="details"
            placeholder="Additional information"
            id="details"
            cols="30"
            rows="10"
          ></textarea>
        </div>

        <button
          type="submit"
          className="btn btn-info btn-lg btn-fix mt-4 quote-btn"
        >
          Request quote
        </button>
      </form>
    </Fragment>
  );
};

export default QuoteForm;
