import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Tools = () => {
  return (
    <Fragment>
      <p className="carousel-heading">Tools:</p>
      <div className="container-fluid">
        <OwlCarousel items={6} margin={8} autoplay={true} loop={true}>
          <div>
            <img
              className="carousel-tools"
              src="img/tools/octoparse.png"
              alt="Octoparse logo"
            />
          </div>
          <div>
            <img
              className="carousel-tools"
              src="img/tools/python-logo.png"
              alt="Python programming language logo"
            />
          </div>
          <div>
            <img
              className="carousel-tools"
              src="img/tools/r-logo.svg"
              alt="R programming logo"
            />
          </div>
          <div>
            <img
              className="carousel-tools"
              src="img/tools/hadoop-logo.png"
              alt="Hadoop logo"
            />
          </div>
          <div>
            <img
              className="carousel-tools"
              src="img/tools/ms-azure.png"
              alt="Microsoft Azure"
            />
          </div>
          <div>
            <img
              className="carousel-tools"
              src="img/tools/apache-spark.png"
              alt="Apache Spark logo"
            />
          </div>
          <div>
            <img
              className="carousel-tools"
              src="img/tools/kafka.png"
              alt="Apache Kafka"
            />
          </div>

          <div>
            <img
              className="carousel-tools"
              src="img/tools/airflow.png"
              alt="Apache Airflow"
            />
          </div>

          <div>
            <img
              className="carousel-tools"
              src="img/tools/google-data-flow.png"
              alt="Google Cloud Data Flow"
            />
          </div>

          <div>
            <img
              className="carousel-tools"
              src="img/tools/snowflake-data-cloud.png"
              alt="Snowflake"
            />
          </div>

          <div>
            <img
              className="carousel-tools"
              src="img/tools/databricks.jpeg"
              alt="Databricks"
            />
          </div>
        </OwlCarousel>
      </div>
    </Fragment>
  );
};

export default Tools;
